#app {
  p.comment {
    font-size: small;
    margin-block-end: 0;
  }
  .App {
    margin-left: 260px;
    .parent {
      position: relative;
    }
    .floating-button {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
    .ant-radio-group {
      padding-top: 4px;
    }
    .tag-group {
      padding-top: 4px;
    }
    // .fixed-button {
    //   position: fixed;
    // }
    // #general-submit {
    //   bottom: 50%;
    // }
    // .inner-button {
    //   position: absolute;
    // }
  }
  .Sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 260px;
    background-color: #f8f9fa;
    .qrcode {
      text-align: center;
    }
  }

  //   .config-line {
  //     display: grid;
  //     grid-template-columns: 2fr 6fr;
  //     align-items: center;
  //     margin-bottom: 10px;

  //     > label {
  //       margin-right: 10px;
  //       text-align: right;
  //     }

  //     > input, .config-line-input {
  //       margin-left: 10px;
  //       max-width: 400px;
  //     }
  //   }
  // }
}
