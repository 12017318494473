#app {
  .Login {
    display: block;
    position: absolute;
    transform: translate(-50%, -61.8%); /* 左右方向的位置为50%, 上下方向的位置为黄金分割比例0.618 */
    left: 50%;
    top: 50%;
    max-width: 300px;
    border-radius: 8px; /* 登录框4个角设置为圆角 */
    background: rgba(255, 255, 255, 0.9); /* 白色背景,不透明度90% */
    text-align: center; /* 让按钮水平居中 */

    h1 {
      text-align: center;
      margin: 0 0 20px;
      color: #333;
    }

    // input[type="password"] {
    //   width: 100%;
    //   padding: 10px;
    //   margin: 5px 0;
    //   border: 1px solid #ccc;
    //   border-radius: 5px;
    //   font-size: 16px;
    // }

    // button {
    //   display: inline-block; /* 使按钮水平居中 */
    //   background-color: #007bff;
    //   color: #fff;
    //   border: none;
    //   padding: 10px 20px;
    //   margin-top: 10px;
    //   cursor: pointer;
    //   border-radius: 5px;
    //   font-size: medium;

    //   &:hover {
    //     background-color: #0056b3;
    //   }
    // }
  }
}